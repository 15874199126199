// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import getAuth from Firebase Auth SDK

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDgPrxHRHT2J9LQQA2k7Ae1KyhD6xK8rWY",
  authDomain: "yooke-9edaa.firebaseapp.com",
  projectId: "yooke-9edaa",
  storageBucket: "yooke-9edaa.appspot.com",
  messagingSenderId: "65048265900",
  appId: "1:65048265900:web:c13112b00ff5ad7d934b09",
  measurementId: "G-N4CFD3JCFM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app); // Initialize and export the auth object

export default firebaseConfig;
